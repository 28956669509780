export const Experience = () => {
  return (
    <article className='max-w-4xl mx-auto'>
      <section className="text-gray-600 body-font">
      <h1 className='text-center font-mono text-5xl py-20 mb-4'>Work Experience</h1>
        <div className="container px-5 pb-24 mx-auto flex flex-wrap">
          <div className="flex relative pb-20 sm:items-center">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 px-2 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-600 text-white relative z-10 title-font font-medium text-sm">2013</div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">University of Southern Indiana</h2>
                <p className="leading-relaxed">Graduated with a Bachlors of Science degree in Computer Information Systems.</p>
              </div>
            </div>
          </div>
          <div className="flex relative pb-20 sm:items-center">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 px-2 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-600 text-white relative z-10 title-font font-medium text-sm">2013</div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Kitch &amp; Screiber Advertising </h2>
                
                <ul className='mt-2'>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Built and maintained WordPress websites for a diverse client base
                  </li>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Communicated with clients to develop user-friendly websites that promoted business objectives
                  </li>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Worked alongside design team to implement Photoshop mockups into WordPress themes
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex relative pb-20 sm:items-center mx-auto">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 px-2 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-600 text-white relative z-10 title-font font-medium text-sm">2015</div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Dr. Dashboard</h2>
                
                <ul className="mt-2">
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Built and maintained online marketing website and assets (WordPress)
                  </li>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Created and managed all online marketing including social media campaigns (Google AdWords, Facebook, Instagram, Twitter, etc.)
                  </li>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Collaborated with Audio Installation specialists to photograph custom audio installation projects
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex relative pb-10 sm:items-center mx-auto">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 px-2 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-600 text-white relative z-10 title-font font-medium text-sm">2016</div>
            <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Specialty Manufacturing</h2>
                
                <ul className="mt-2">
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Designed, built, and implemented a conveyor line quality control barcode scanner application (Express, Vue)
                  </li>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Responsible for building and maintaining eCommerce Store for corporation (Shopify)
                  </li>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Maintain control of user access to ensure integrity of Active Directory and Office 365 email systems
                  </li>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Responsible for creating and maintaining private label programs (Illustrator, InDesign)
                  </li>
                  <li>
                    <span className="bg-yellow-100 text-yellow-600 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="w-3 h-3" viewBox="0 0 24 24">
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Coordinate with customers to guarantee new Private label Programs launch on schedule
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  )
}